import { useSearchParams, Navigate } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import jwt from 'jsonwebtoken';

export default function GoogleLogin() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  let error = searchParams.get('error');
  let token = searchParams.get('token');
  let page = searchParams.get('page');
  if (error || !token) {
    message.error(t(`Res.${error}`));
    return <Navigate replace to='/'></Navigate>;
  }
  if (page.match(/admin/)) {
    window.localStorage.setItem('adminToken', token);
    let tokenData = jwt.decode(token);
    window.localStorage.setItem('adminTokenData', JSON.stringify(tokenData));
  } else {
    window.localStorage.setItem('token', token);
    let tokenData = jwt.decode(token);
    window.localStorage.setItem('tokenData', JSON.stringify(tokenData));
  }

  return <Navigate replace to={page || '/user/home'}></Navigate>;
}
