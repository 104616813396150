import { zh as ResZh } from './res';

const zh = {
  Res: {
    ...ResZh,
  },
  DPN: 'Deeper Network DPN',
  Register: '註冊',
  Email: '郵箱',
  Username: '用戶名',
  'Verification Code': '驗證碼',
  Password: '密碼',
  'New Password': '新密碼',
  'Repeat Password': '重複密碼',
  Send: '發送驗證碼',
  Login: '登錄',
  'Reset Password': '重置密碼',
  'Forget password': '忘記密碼',
  Referer: '推薦人',
  Download: '下載',
  Home: '主頁',
  'Benefits History': '權益記錄',
  'Invitation Link': '邀請鏈接',
  'Expiration Date': '權益結束日期',
  'Click to copy': '點擊複製',
  Renewal: '續費',
  Type: '類型',
  Invitee: '受邀者',
  'Order Id': '訂單編號',
  'Duration of Benefit Acquisition': '權益時間',
  'Benefit Changes': '權益時間變更',

  days: '天',
  from: '從',
  to: '到',
  country: '地區',

  Submit: '提交',
  Cancel: '取消',

  'Please input your email!': '請輸入郵箱地址',
  'Please input your username!': '請輸入用戶名',
  'Please input your verification code!': '請輸入驗證碼',
  'register|Please input your password!':
    '請輸入一個複雜密碼(至少8位，包含數字、字母)',
  'Please input your password!': '請輸入密碼',
  'invalid email': '郵箱地址不正確',
  'Confirmed passwords do not match': '兩次密碼不一致',
  'Your Invitation link has been copied!': '您的邀請鏈接已複製。',
  Copied: '已複製',
  'Bind the device SN to get permanent VIP.': '綁定設備SN號可以獲得永久VIP。',
  Bind: '綁定',
  'Device IP': '設備訪問IP',
  'Device SN': '設備SN',
  'Please input ip!': '請輸入IP',
  'Please input device sn!': '請輸入SN',
  'Authorized, please open the App to check the login status.':
    '已授權，請打開App查看登錄狀態',
  'Download DPN App': 'DPN App 下載',

  rightType: {
    trial: '試用',
    referer: '邀請獎勵',
    order: '訂單',
  },
};

export default zh;
