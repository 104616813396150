import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.scss';
import languageIcon from 'assets/imgs/language.png';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const changeLocale = () => {
    const currentLocale = i18n.language;
    if (currentLocale === 'en') {
      i18n.changeLanguage('zh');
      window.localStorage.setItem('locale', 'zh');
    } else {
      i18n.changeLanguage('en');
      window.localStorage.setItem('locale', 'en');
    }
  };
  const toLogin = () => {
    navigate('/login');
  };

  return (
    <header className={styles.header}>
      <div></div>
      <div className={styles['nav-box']}>
        <div>
          <Button
            onClick={changeLocale}
            type='text'
            icon={
              <img style={{ width: 18 }} src={languageIcon} alt='' />
            }></Button>
        </div>
      </div>
    </header>
  );
}

export default Header;
