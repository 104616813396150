import React from 'react';
import { useLocation } from 'react-router-dom';
const PlanCard = ({
  title,
  price,
  savings,
  features,
  buttonText,
  isPopular,
}) => (
  <div
    className={`bg-white rounded-lg shadow-md p-6 flex flex-col ${
      isPopular ? 'border-4 border-blue-500' : ''
    }`}>
    {isPopular ? (
      <div
        className={`-mt-10 mb-4 ${
          isPopular ? 'bg-blue-500 text-white' : 'bg-red-100 text-red-600'
        } py-1 px-3 rounded-full self-start text-sm font-semibold`}>
        Save {savings}%
      </div>
    ) : (
      <div></div>
    )}
    <h3 className='text-xl font-bold mb-2'>{title}</h3>
    <p className='text-3xl font-bold mb-1'>
      ${price} <span className='text-sm font-normal'>/month</span>
    </p>
    <p className='text-sm text-red-600 mb-4'>+ 1 months of service</p>
    <button
      className={`py-2 px-4 rounded transition duration-300 ${
        isPopular
          ? 'bg-blue-500 text-white hover:bg-blue-600'
          : 'bg-white text-blue-500 border border-blue-500 hover:bg-blue-50'
      }`}>
      {buttonText}
    </button>
    {title === 'Premium' && (
      <p className='text-xs text-green-600 mt-4 flex items-center justify-center'>
        <svg className='w-4 h-4 mr-1' fill='currentColor' viewBox='0 0 20 20'>
          <path
            fillRule='evenodd'
            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
            clipRule='evenodd'
          />
        </svg>
        30-day money-back guarantee
      </p>
    )}
  </div>
);

const FeatureRow = ({ feature, plans }) => (
  <tr className='border-b'>
    <td className='py-2 flex items-center'>{feature}</td>
    {plans.map((plan, index) => (
      <td key={index} className='py-2 text-center'>
        {feature === 'Global nodes' ? (
          plan.title === 'Trial' ? (
            'up to 100,000+'
          ) : (
            'up to 100,000+'
          )
        ) : feature === 'Nodes per tunnel' ? (
          plan.title === 'Trial' ? (
            '3'
          ) : (
            '8'
          )
        ) : feature === 'Node quality' ? (
          plan.title === 'Trial' ? (
            'Normal'
          ) : (
            'High-speed'
          )
        ) : feature === 'Node Selection Mode' ? (
          plan.title === 'Trial' ? (
            'Normal'
          ) : (
            'Turbo'
          )
        ) : plan.features.includes(feature) ? (
          <svg
            className='w-6 h-6 mx-auto text-green-500'
            fill='currentColor'
            viewBox='0 0 20 20'>
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'
            />
          </svg>
        ) : (
          <span className='text-gray-300'>-</span>
        )}
      </td>
    ))}
  </tr>
);

function useIsApp() {
  const location = useLocation();
  return location.search.includes('app=true');
}

const Pricing = () => {
  const isApp = useIsApp();
  const plans = [
    {
      title: 'Trial',
      price: 0,
      savings: 50,
      features: [
        'Protect up to 10 devices simultaneously',
        'Smart routing',
        'App relocator',
        'HTTPS filter',
        'Block ads',
        'Block trackers',
        'Block Malicious',
        'Global nodes',
        'Nodes per tunnel: 3',
        'Node quality: Normal',
        'Node Selection Mode: Normal',
      ],
      buttonText: isApp ? 'Trial' : 'Get Trial',
      isPopular: false,
    },
    {
      title: 'Premium',
      price: '??',
      savings: 50,
      features: [
        'Protect up to 10 devices simultaneously',
        'Smart routing',
        'App relocator',
        'HTTPS filter',
        'Block ads',
        'Block trackers',
        'Block Malicious',
        'Global nodes',
        'Nodes per tunnel: 8',
        'Node quality: High-speed',
        'Node Selection Mode: Turbo',
      ],
      buttonText: isApp ? 'Premium' : 'Get Premium',
      isPopular: true,
    },
  ];

  const allFeatures = [
    'Global nodes',
    'Protect up to 10 devices simultaneously',
    'Smart routing',
    'App relocator',
    'HTTPS filter',
    'Block ads',
    'Block trackers',
    'Block Malicious',
    'Nodes per tunnel',
    'Node quality',
    'Node Selection Mode',
  ];

  return (
    <div className='container mx-auto px-4 py-8'>
      {!isApp && (
        <h2 className='text-2xl font-bold text-center mb-8'>Pricing</h2>
      )}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-12'>
        {plans.map((plan, index) => (
          <PlanCard key={index} {...plan} />
        ))}
      </div>
      <div className='overflow-x-auto'>
        <table className='w-full'>
          <thead>
            <tr className='bg-gray-100'>
              <th className='py-2 px-4 text-left'>Features</th>
              {plans.map((plan, index) => (
                <th key={index} className='py-2 px-4 text-center'>
                  {plan.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allFeatures.map((feature, index) => (
              <FeatureRow key={index} feature={feature} plans={plans} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pricing;
