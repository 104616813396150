import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Download from 'containers/Download/Download';
import GoogleLogin from 'containers/Google/GoogleLogin';
import GoogleLoginApp from 'containers/Google/GoogleLoginApp';
import Turnstile from 'containers/Turnstile/Turnstile';
import Pricing from 'containers/Pricing/Pricing';
export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Download />} />
      <Route path='/download' element={<Download />} />
      <Route path='/oauth/login' element={<GoogleLogin />} />
      <Route path='/app/oauth/login' element={<GoogleLoginApp />} />
      <Route path='/turnstile' element={<Turnstile />} />
      <Route path='/pricing' element={<Pricing />} />
    </Routes>
  );
}
