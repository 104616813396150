import fetch from './fetch';

function sendCode(email) {
  return fetch({
    url: '/user/code',
    method: 'POST',
    data: {
      email,
    },
  });
}

function register(data) {
  return fetch({
    url: '/user/register',
    method: 'POST',
    data,
  }).then((res) => {
    if (res) {
      window.localStorage.setItem('token', res.token);
      window.localStorage.setItem('tokenData', JSON.stringify(res.tokenData));
    }
    return res;
  });
}

export function checkTurnstile(data) {
  return fetch({
    url: '/user/turnstile',
    method: 'POST',
    data,
  });
}

function login(data) {
  return fetch({
    url: '/user/login',
    method: 'POST',
    data,
  }).then((res) => {
    if (res) {
      window.localStorage.setItem('token', res.token);
      window.localStorage.setItem('tokenData', JSON.stringify(res.tokenData));
    }
    return res;
  });
}

function resetPassword(data) {
  return fetch({
    url: '/user/resetPassword',
    method: 'POST',
    data,
  });
}

const accessKey = {
  list() {
    return fetch({
      url: '/user/accessKey',
    });
  },
  generate() {
    return fetch({
      url: '/user/accessKey/generate',
      method: 'POST',
    });
  },
};

const right = {
  list(search) {
    return fetch({
      url: '/user/right/list',
      params: {
        search,
      },
    });
  },
};

const api = {
  accessKey,
  right,
  sendCode,
  register,
  login,
  resetPassword,
};

export default api;
