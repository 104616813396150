import React from 'react';

import bgImg from 'assets/imgs/bg.png';
import bgImgMobile from 'assets/imgs/bg-mobile.png';

import Header from 'components/Header/Header';
import styles from './Download.module.scss';
import DownloadBox from 'components/DownloadBox/DownloadBox';
import { useTranslation } from 'react-i18next';

function Download() {
  const { t } = useTranslation();
  return (
    <main
      className={styles.main}
      style={{
        backgroundImage: `url(${
          window.innerWidth < 800 ? bgImgMobile : bgImg
        })`,
      }}>
      <Header />

      <div className={styles.container}>
        <h3 className={styles['download-title']}>{t('Download DPN App')}</h3>
        <DownloadBox></DownloadBox>
      </div>
      <footer className={styles['footer']}>
        <div className={styles['copyright-box']}>
          Copyright © Deeper Network in 2024. All rights reserved
        </div>
        <div>
          <a
            style={{ color: '#7f7f7f' }}
            target='_blank'
            href='https://shop.deeper.network/pages/privacy-policy'
            rel='noreferrer'>
            Privacy & Security
          </a>
        </div>
      </footer>
    </main>
  );
}

export default Download;
