import React, { useEffect } from 'react';
import { checkTurnstile } from '../../apis/user';
import { useLocation } from 'react-router-dom';

export default function Turnstile() {
  const location = useLocation();
  const searchObj = new URLSearchParams(location.search);
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    script.async = true;
    script.onload = () => {
      console.log('Turnstile loaded');
      window.turnstile.render('#cf-turnstile-container', {
        sitekey: '0x4AAAAAAAiXUmOf5emuqvtI',
        callback: function (token) {
          console.log(`Challenge Success ${token}`);
          checkTurnstile({
            token: token,
            userKey: searchObj.get('k'),
          });
        },
        'error-callback': function (err) {
          console.log(`Challenge Error ${err}`);
        },
      });
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div id='cf-turnstile-container'></div>
    </div>
  );
}
