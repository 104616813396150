import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function GoogleLoginApp() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  let error = searchParams.get('error');
  useEffect(() => {
    if (error) {
      message.error(t(`Res.${error}`), 0);
      return;
    }
    message.success(
      t('Authorized, please open the App to check the login status.'),
      0,
    );
    setTimeout(() => {
      window.close();
    }, 5000);
  }, [error, t]);

  return <div></div>;
}
