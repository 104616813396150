import { en as ResEn } from './res';

const en = {
  Res: {
    ...ResEn,
  },
  DPN: 'Deeper Network DPN',
  'register|Please input your password!':
    'Please enter a complex password (at least 8 digits, including numbers and letters)',
  Register: 'Register',
  rightType: {
    trial: 'trial',
    referer: 'invitation reward',
    order: 'order',
  },
};

export default en;
