export const en = {
  InvalidEmail: 'Invalid email',
  AlreadySent: 'A verification code has been sent, please try again later.',
  InvalidCode: 'The verification code is wrong or has expired.',
  EmailRegistered: 'The email has been registered.',
  LoginError: 'Username or password entered incorrectly.',
  InvalidBody: 'Please check the information again.',
  SNError: 'The device SN number is incorrect or already occupied.',
  BEP20Error: 'BEP20 address is incorrect or already occupied.',
  NoDeeperChain: 'Please bind the DeeperChain wallet on the device.',
  InvalidReferer: 'Invalid Referer',

  SendSuccess: 'Verification code sent successfully',
  RegisterSuccess: 'registration success',
  Welcome: 'Welcome, ',
  PasswordResetSuccess: 'Password reset successfully.',
  CreateSuccess: 'Created successfully.',
  DeleteSuccess: 'Delete successfully.',
  InsufficientRightTime: 'Insufficient Right Time',
  GoogleEmailRegistered: 'Google email registered.',
  GoogleLoginError: 'Google verification failed.',
  LoginLocked5m:
    'Your account has been temporarily locked for 5 minutes. Please try again later.',
};

export const zh = {
  InvalidEmail: '郵箱不正確',
  AlreadySent: '驗證碼已發送，請稍後再試',
  InvalidCode: '驗證碼錯誤或已過期',
  EmailRegistered: '郵箱地址已經被註冊',
  LoginError: '用戶名或密碼錯誤',
  InvalidBody: '請在此檢查並完善信息。',
  SNError: '設備SN號已被佔用或者不正確',
  BEP20Error: 'BEP20 地址不正確或已被佔用',
  NoDeeperChain: '請在設備上綁定DeeperChain錢包',
  InvalidReferer: '推薦人填寫有誤',

  SendSuccess: '驗證碼發送成功',
  RegisterSuccess: '註冊成功!',
  Welcome: '歡迎您，',
  PasswordResetSuccess: '密碼重置成功',
  CreateSuccess: '創建成功',
  DeleteSuccess: '刪除成功',
  InsufficientRightTime: '權益時間不足，請充值',
  GoogleEmailRegistered: 'Google賬戶對應的Email已經被註冊。',
  GoogleLoginError: 'Google驗證失敗',
  LoginLocked5m: '您的帳戶已被暫時鎖定 5 分鐘。請稍後再試。',
};
