import React from 'react';
import AndroidIcon from 'assets/imgs/android.png';
import WindowsIcon from 'assets/imgs/windows.png';
import LinuxIcon from 'assets/imgs/linux.png';
import MacOSIcon from 'assets/imgs/apple.png';
import IOSIcon from 'assets/imgs/apple-store.png';

import styles from './DownloadBox.module.scss';
import { RightOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';

export default function DownloadBox() {
  return (
    <div className={styles['download-box']}>
      <div className={styles['download-list-box']}>
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <a
                    className={styles['dropdown-item']}
                    target='_blank'
                    href={
                      'https://s3.amazonaws.com/downloads.deeper.network/DPN/build/DPN-App-x86_64.dmg' +
                      `?${Date.now()}`
                    }
                    rel='noopener noreferrer'>
                    macOS x86 ( Intel chip )
                  </a>
                ),
              },
              {
                key: '2',
                label: (
                  <a
                    className={styles['dropdown-item']}
                    target='_blank'
                    href={
                      'https://s3.amazonaws.com/downloads.deeper.network/DPN/build/DPN-App-arm64.dmg' +
                      `?${Date.now()}`
                    }
                    rel='noopener noreferrer'>
                    macOS arm64 ( Apple silicon )
                  </a>
                ),
              },
            ],
          }}>
          <div className={styles['download-item']}>
            <Button>
              <img className={styles['icon-img']} src={MacOSIcon} alt='' />

              <div>macOS </div>
              <RightOutlined className={styles['arrow-icon']}></RightOutlined>
            </Button>
          </div>
        </Dropdown>
        <a
          href={
            'https://s3.amazonaws.com/downloads.deeper.network/DPN/build/DPN-App.exe' +
            `?${Date.now()}`
          }
          target='_blank'
          rel='noopener noreferrer'
          className={styles['download-item']}>
          <Button>
            <img className={styles['icon-img']} src={WindowsIcon} alt='' />

            <div>Windows</div>
          </Button>
        </a>
        <a
          href={
            'https://s3.amazonaws.com/downloads.deeper.network/DPN/build/DPN-App-x86_64.deb' +
            `?${Date.now()}`
          }
          target='_blank'
          rel='noopener noreferrer'
          className={styles['download-item']}>
          <Button className={styles['download-item']}>
            <img className={styles['icon-img']} src={LinuxIcon} alt='' />

            <div>Linux</div>
          </Button>
        </a>
        <a
          href='https://testflight.apple.com/join/zTvCn1Qe'
          target='_blank'
          rel='noopener noreferrer'
          className={styles['download-item']}>
          <Button className={styles['download-item']}>
            <img className={styles['icon-img']} src={IOSIcon} alt='' />
            <div>App Store</div>
          </Button>
        </a>
        <a
          href={
            'https://s3.amazonaws.com/downloads.deeper.network/DPN/build/DPN-App.apk' +
            `?${Date.now()}`
          }
          target='_blank'
          rel='noopener noreferrer'
          className={styles['download-item']}>
          <Button className={styles['download-item']}>
            <img className={styles['icon-img']} src={AndroidIcon} alt='' />
            <div>Android</div>
          </Button>
        </a>
      </div>
    </div>
  );
}
