import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import './index.less';

import axios from 'axios';
import Router from './Router';

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:7001/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Router />
  </BrowserRouter>,
);
